.input-label {
    position: absolute;
    pointer-events: none;
    left: 8px;
    top: 8px;
    transition: top 0.3s, font-size 0.3s;
  }
  
  .input-label-focused {
    top: -10px;
    font-size: 12px;
    background-color: #ffffff;
  }

  .required-asterisk {
    color: red;
  }

  .ant-form-item-explain-error {
    color: #f8080c; 
}